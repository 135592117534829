import { HarmBlockMethod, HarmBlockThreshold, HarmCategory, SafetySetting } from "@firebase/vertexai-preview";

export const safetySettings: SafetySetting[] = [

    {
        category: HarmCategory.HARM_CATEGORY_HARASSMENT,
        threshold: HarmBlockThreshold.BLOCK_LOW_AND_ABOVE,
        method: HarmBlockMethod.HARM_BLOCK_METHOD_UNSPECIFIED
    },
    {
        category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
        threshold: HarmBlockThreshold.BLOCK_LOW_AND_ABOVE,
        method: HarmBlockMethod.HARM_BLOCK_METHOD_UNSPECIFIED
    },
    {
        category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
        threshold: HarmBlockThreshold.BLOCK_LOW_AND_ABOVE,
        method: HarmBlockMethod.HARM_BLOCK_METHOD_UNSPECIFIED
    }
];